import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import axios from "../api/axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SUPPORT_DAYS_COVERAGE = {
  "month": 30,
  "year": 365,
}

const AvailablePlans = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [prices, setPrices] = useState([]);

  const fetchPrices = async () => {
    const { data: res } = await axios.get("api/subs/prices/v2");
    setPrices(res.data);
  };

  useEffect(() => {
    if (user) {
      navigate("/subscription");
      return;
    }
    fetchPrices();
  }, [user]);

  return (
    <section>
      <Container>
        <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1 className="display-4">Pricing</h1>
          <p className="lead">Choose the plan which best suit your budget.</p>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="card-deck mb-3 text-center d-flex align-items-center gap-4">
            {prices.map((price) => {
              return (
                <div key={price.id} className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal text-capitalize">
                      {price.lookup_key}
                    </h4>
                  </div>
                  <div className="card-body d-flex align-items-center flex-column">
                    <h1 className="card-title pricing-card-title fs-1">
                      ${price.unit_amount / 100}{" "}
                      <small className="text-muted fs-4">
                        / {price.recurring.interval}
                      </small>
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>{SUPPORT_DAYS_COVERAGE[price.recurring.interval]} days support</li>
                      <li>{SUPPORT_DAYS_COVERAGE[price.recurring.interval]} days coverage</li>
                      <li>Priority email support</li>
                      <li>Help center access</li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => navigate("/")}
          >
            Confirm
          </Button>
        </div>
        <div className=" d-flex justify-content-center  mt-5 text-center">
          <h5>
            Note : Please create an account or login to your existing account. Then go to subscription page for purchasing desired plan.
          </h5>
        </div>
      </Container>
    </section>
  );
};
export default AvailablePlans;

<div className=" d-flex justify-content-center  mt-5 text-center">
  <h5>
    Note : Please create an account or login to your existing account. Then go to subscription page for purchasing desired plan.
  </h5>
</div>
